import React, { Fragment } from "react";

import {
  checkValidDates,
  checkBox,
  showTypeSelect,
  hasObjLabel,
  hasObjValue,
  newVsOldDate,
  formatValue,
  readData,
  iterateFieldSets,
} from "../../utils/common";
import { DateTime } from "luxon";

import { defaultDef, fieldDef, fieldDefTypeSelect } from "./legDefinition";

const IconsGoogle = {
  OpenLeg: "expand_more",
  CloseLeg: "expand_less",
  AddLeg: "add",
  RemoveLeg: "close",
  Share: "share",
  Search: "search",
  Locked: "lock",
  Unlocked: "lock_open",
  Linked: "link",
  Unlinked: "link_off",
  More: "more_horiz",
};
const staySite = "booking";

const statusTypes = {
  planning: {
    color: "warning",
    text: "planning",
  },
  booked: {
    color: "success",
    text: "booked",
  },
  locked: {
    color: "success",
    text: "locked",
  },
  past: {
    color: "success",
    text: "past",
  },
};

const subTypeButtons = {
  transport: ["flight", "bus", "train", "taxi", "car", "ferry", "boat"],
  stay: [],
  eat: ["restaurant", "market", "festival", "food trucks"],
  activity: ["tour", "sport", "festival", "other"],
};

const LegItem = ({
  IS_MOBILE,
  leg,
  idx,
  onLegChange,
  addLeg,
  removeLeg,
  onTypeChange,
  onLinkClick,
  showHideRow,
  bShowRow,
  showHideMoreLeg,
  bShowMoreLeg,
}) => {
  const { from, to, legType, stayDetails } = leg;
  const legTypeLower = legType.toLowerCase();
  const sections = fieldDef(legTypeLower, onLegChange);
  const haveMoreFields =
    JSON.stringify(sections["fieldSet"]) !==
    JSON.stringify(sections["fieldsMore"]);

  /*

  to-do:
  - date dropdown doesn't trigger the totals update for some reason.  other fields are fine
  - fix the styling for either date / location drop down or sync others
  - fix the location loading and filtering
  - fix the location default on loading
  - fix the location default on adding new leg
  - fix the reloading of the holiday values
  - make the date dropdown disappear on date click (maybe)
  - need to make add destination work if doesn't exist
  - date on stays needs to ignore time
  - 

  */

  const formatURLDate = (d, legTypeLower) => {
    let dateTime = checkValidDates(d);
    switch (legTypeLower) {
      case "transport":
        return dateTime.toFormat("yyLLdd");
      case "stay":
        switch (staySite) {
          case "agoda":
            return dateTime.toFormat("yyyy-LL-dd");
          case "trivago":
            return dateTime.toFormat("yyyy-LL-dd");
          case "booking":
            return [
              dateTime.toFormat("yyyy"),
              dateTime.toFormat("L"),
              dateTime.toFormat("d"),
            ];
          default:
            return dateTime.toFormat("yyyy-LL-dd");
        }
      default:
        return dateTime.toFormat("yyLLdd");
    }
  };

  const showFields = (idx, legTypeLower, fieldSet, bShowRow) => {
    if (!legTypeLower || legTypeLower === "activity")
      legTypeLower = "transport";

    // let fldDef = fieldDef[legTypeLower];
    let fldDef = fieldDef(legTypeLower, onLegChange);
    let styls = { col: "col-auto g-1", row: "row" };
    let k = ["legI", idx, legTypeLower].join("-");
    let datePassed = fieldSet?.toDateTime?.field
      ? newVsOldDate(DateTime.now(), ">=", fieldSet.toDateTime.field)
      : false;

    let status = "planning";
    if (datePassed) {
      status = "past";
    } else if (leg?.locked) {
      status = "locked";
    } else if (leg?.status) {
      status = leg.status;
    }

    return (
      <Fragment>
        <div
          className={`container border-top m-0 p-2 bg-light`}
          key={k + "-contain-min-title"}
        >
          <div
            className={"row px-2 align-items-center"}
            key={k + "-row-min-title"}
            // onClick={(e) => showHideRow(e, leg._id)}
          >
            <div className={"col-10 g-1 "} key={k + "-col-min-title"}>
              <div
                className={"row px-2 align-items-center"}
                key={k + "-row-type-min-title-sub"}
              >
                <div
                  className={"col-8 g-1 "}
                  key={k + "-col-type-min-title-sub"}
                >
                  {showExpandTitleRow(k)}
                </div>
                <div className={"col-4 g-1 "} key={k + "-col-type-min-status"}>
                  {showStatusPill(status)}
                </div>
              </div>
              <div
                className={"row px-2 align-items-center"}
                key={k + "-row-type-min-destination"}
              >
                <div
                  className={"col-12 g-1 "}
                  key={k + "-col-type-min-destination"}
                >
                  {showToFromTitleRow(leg)}
                </div>
              </div>
              <div
                className={"row px-2 align-items-center"}
                key={k + "-row-type-min-date-sub"}
              >
                <div
                  className={"col-12 g-1 "}
                  key={k + "-col-type-min-date-sub"}
                >
                  {showDateTitleRow(fldDef)}
                </div>
              </div>
            </div>
            <div className={"col-2 g-1 "} key={k + "-col-type-min-btn-sub"}>
              <div className="text-center">
                {showLinkedButton(defaultDef("linkToLast", onLegChange), idx)}
              </div>
              <div className="text-center">
                {showLockedButton(defaultDef("locked", onLegChange), idx)}
              </div>
            </div>
          </div>
        </div>
        {!bShowRow ? "" : showInputFields(styls, k, idx)}{" "}
      </Fragment>
    );
  };

  const showExpandTitleRow = (k) => (
    <Fragment>
      {showExpandButton(bShowRow, idx, leg._id)}
      <span
        className="h5"
        key={k + "-h5-type-min-title-sub"}
        onClick={(e) =>
          showHideRow({
            target: {
              value: leg._id,
            },
          })
        }
      >
        {leg.legType}
      </span>
    </Fragment>
  );

  const showToFromTitleRow = (leg) => (
    <Fragment>
      {leg?.from?.city ? hasObjLabel(leg.from.city) : ""}
      {leg?.to?.city ? " to " + hasObjLabel(leg.to.city) : ""}
    </Fragment>
  );

  const showDateTitleRow = (fldDef) => (
    <Fragment>
      {leg?.from?.dateTime
        ? formatValue(
            "date",
            leg.from.dateTime,
            fldDef?.fieldsDef?.fromDateTime?.format
          )
        : ""}
      {leg?.to?.dateTime
        ? " - " +
          formatValue(
            "date",
            leg.to.dateTime,
            fldDef?.fieldsDef?.toDateTime?.format
          )
        : ""}
    </Fragment>
  );

  const showStatusPill = (status) => (
    <div className="float-end">
      <span className={`badge rounded-pill bg-` + statusTypes[status].color}>
        {statusTypes[status].text}
      </span>
    </div>
  );

  const showInputFields = (styls, k, idx) => (
    <div className={`container border-top m-0 p-2`}>
      <div className={styls.row + " p-0"} key={k + "-row-type"}>
        <div className={"col-6 g-1 "} key={k + "-col-type"}>
          {showTypeSelect(k, idx, leg, fieldDefTypeSelect(onTypeChange), [
            "Select",
            "Transport",
            "Stay",
            "Activity",
            "Eat",
          ])}
        </div>
        <div className={"col-6 float-right g-1"} key={k + "-col-btns"}>
          <div className="float-end">
            {checkBox(k, leg, defaultDef("linkToLast", onLegChange), idx, {})}
            {showSearchButton(onLinkClick, idx)}
            {/* {shareIt(idx)} */}
            {iconButton(idx, "RemoveLeg", { onchange: removeLeg }, "", {})}
          </div>
        </div>
      </div>
      {showHorizontalButtons(idx, k, leg)}
      {showDataFields(bShowMoreLeg)}
      <div className={styls.row + " e-1"} key={k + "-row-subpart"}>
        <div className={"col-4 g-1 "} key={k + "-col-subpart"}>
          {checkBox(
            k,
            leg,
            defaultDef("travelDetailsSubPart", onLegChange),
            idx,
            {}
          )}
        </div>
        <div className={"col-4 g-1 "} key={k + "-col-addLeg"}>
          <div className="d-flex justify-content-center">
            {iconButton(idx, "AddLeg", { onchange: addLeg }, "", {
              className: "btn-primary",
            })}
          </div>
        </div>
        <div className={"col-4 g-1 "} key={k + "-col-showMore"}>
          <div className="float-end">
            {haveMoreFields ? (
              <Fragment>
                Show {bShowMoreLeg ? "Less" : "More"}
                {showMoreLegButton(leg._id, idx)}
              </Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const showHorizontalButtons = (idx, k, leg) => {
    if (!subTypeButtons?.[legTypeLower]?.length) return "";
    let buttons = subTypeButtons[legTypeLower];
    let horizScrollRow = { overflowX: "auto", whiteSpace: "nowrap" };
    let horizScrollCol = {
      overflowX: "auto",
      whiteSpace: "nowrap",
      display: "inline-block",
      float: "none",
    };
    let k2 = k + "-subtype";

    return (
      <div className={"row p-1"} key={k2 + "-row"} style={horizScrollRow}>
        <div
          className={"col-12 g-1 my-1"}
          key={k2 + "-col"}
          style={horizScrollCol}
        >
          {buttons.map((button) => {
            let subButStyle =
              leg?.subType && button === leg.subType ? "success" : "primary";
            return (
              <button
                key={`${k2 + idx}-btn-${button}`}
                className={`badge rounded-pill me-1 bg-${subButStyle}`}
                style={{ minWidth: "50px" }}
                onClick={(e) =>
                  onLegChange({ target: { id: "subType", value: button } }, idx)
                }
              >
                <span className="h6">
                  <small>{button}</small>
                </span>
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  // shows all the data fields from the fieldsection
  // which defines the field grouping
  const showDataFields = (bShowMoreLeg) => {
    let styls = { col: "col-auto g-1", row: "row" };
    let k = ["legI", idx, legTypeLower].join("-");

    // let sections = fieldDef(legTypeLower, onLegChange);
    let secName = bShowMoreLeg ? "fieldsMore" : "fieldSet";

    return (
      <Fragment>
        {iterateFieldSets(leg, sections, secName, styls, k, idx)}
      </Fragment>
    );
  };

  const showSearchButton = (onLinkClick, idx) => {
    let showSearch = false;
    let href;
    switch (legTypeLower) {
      default:
      case "transport":
        showSearch =
          from?.city && to?.city && from?.dateTime && to?.dateTime
            ? true
            : false;

        if (showSearch) {
          let dateTimeFrom = !from?.dateTime
            ? formatURLDate(DateTime.now())
            : formatURLDate(from.dateTime, legTypeLower);
          let dateTimeTo = !to?.dateTime
            ? formatURLDate(DateTime.now())
            : formatURLDate(to.dateTime, legTypeLower);

          let destFromTo = [hasObjValue(from?.city), hasObjValue(to?.city)];
          let dateFromTo = [dateTimeFrom, dateTimeTo];
          href = getURLs("transport", { dateFromTo, destFromTo });
        }
        break;
      case "stay":
        showSearch =
          from?.city && from?.dateTime && to?.dateTime ? true : false;

        if (showSearch) {
          let dateTimeFrom = !from?.dateTime
            ? formatURLDate(DateTime.now())
            : formatURLDate(from.dateTime, legTypeLower);
          let dateTimeTo = !to?.dateTime
            ? formatURLDate(DateTime.now())
            : formatURLDate(to.dateTime, legTypeLower);

          let destFromTo = [hasObjValue(from?.city)];
          let dateFromTo = [dateTimeFrom, dateTimeTo];
          href = getURLs("stay", { dateFromTo, destFromTo });
        }
        break;
      case "activity":
        showSearch = stayDetails?.company ? true : false;

        if (showSearch) {
          let dateTimeFrom = !from?.dateTime
            ? formatURLDate(DateTime.now())
            : formatURLDate(from.dateTime, legTypeLower);

          let destFromTo = [hasObjValue(from?.city)];
          let dateFromTo = [dateTimeFrom];

          let params = {
            destFromTo,
            dateFromTo,
            stayDetails: { company: stayDetails.company },
          };
          href = getURLs("eat", params);
        }
        break;
      case "eat":
        showSearch = stayDetails?.company ? true : false;

        if (showSearch) {
          let dateTimeFrom = !from?.dateTime
            ? formatURLDate(DateTime.now())
            : formatURLDate(from.dateTime, legTypeLower);
          let destFromTo = [hasObjValue(from?.city)];
          let dateFromTo = [dateTimeFrom];

          let params = {
            destFromTo,
            dateFromTo,
            stayDetails: { company: stayDetails.company },
          };
          href = getURLs("eat", params);
        }
        break;
    }

    return iconButton(idx, "Search", { onchange: onLinkClick }, href, {
      disabled: !showSearch,
    });
  };

  const getURLs = (legTypeLower, params) => {
    let href;
    switch (legTypeLower) {
      default:
      case "transport":
        href =
          `https://www.skyscanner.com.sg/transport/flights/` +
          `${params.destFromTo[0].toLowerCase()}/${params.destFromTo[1].toLowerCase()}/${
            params.dateFromTo[0]
          }/${params.dateFromTo[1]}/?` +
          `adults=1&adultsv2=1&cabinclass=economy&children=0&childrenv2=&destinationentityid=27548276&inboundaltsenabled=false&infants=0` +
          `&originentityid=27546111&outboundaltsenabled=false&preferdirects=false&ref=home&rtn=1`;
        return href;
      case "stay":
        // let destFromTo = ["Phuket"];

        // href =
        //   `https://www.booking.com/searchresults.en-gb.html?ss=${params.destFromTo[0].toLowerCase()}&aid=304142&lang=en-gb&sb=1&src_elem=sb&src=searchresults&dest_type=city` +
        //   `&ac_position=0&ac_click_type=b&ac_langcode=en&ac_suggestion_list_length=5&search_selected=true&search_pageview_id=443b1acb27de01d2` +
        //   `&checkin=${params.dateFromTo[0]}&checkout=${params.dateFromTo[1]}&group_adults=2&no_rooms=1&group_children=0&sb_travel_purpose=leisure`;

        // href = `https://www.agoda.com/search?tick=637871702975&txtuuid=4dd4ec8f-d1bd-43e4-b9e1-d0d3866ccf4f&locale=en-us&ckuid=32c57016-f09c-4e0c-8ec6-9f1c357103ff&prid=0&gclid=CjwKCAjwgr6TBhAGEiwA3aVuIWB_UUKuU_vnmq9_rlT968aD1I0RUlIL2VkQLwvcUcie1HiJxtgnoBoCC70QAvD_BwE&currency=SGD&correlationId=cb791a69-345e-45fc-bf9b-60ad1c40660c&pageTypeId=1&realLanguageId=1&languageId=1&origin=SG&cid=1891471&tag=ed790da4-e0f6-a4cf-ca18-d0d6a5da36d0&userId=32c57016-f09c-4e0c-8ec6-9f1c357103ff&whitelabelid=1&loginLvl=0&storefrontId=3&currencyId=5&currencyCode=SGD&htmlLanguage=en-us&cultureInfoName=en-us&memberId=687750&machineName=sg-acmweb-6009&trafficGroupId=5&sessionId=w4sretg2mmmjtaiyiulxwzle&trafficSubGroupId=122&aid=82361&useFullPageLogin=true&cttp=4&isRealUser=true&mode=production&checkIn=${dateFromTo[0]}&checkOut=${dateFromTo[1]}&rooms=1&adults=2&children=0&priceCur=SGD&los=1&textToSearch=${destFromTo[0]}&productType=-1&travellerType=1&familyMode=off`;
        // href = `https://www.agoda.com/search?city=16056&tick=637871702975&txtuuid=4dd4ec8f-d1bd-43e4-b9e1-d0d3866ccf4f&locale=en-us&ckuid=32c57016-f09c-4e0c-8ec6-9f1c357103ff&prid=0&gclid=CjwKCAjwgr6TBhAGEiwA3aVuIWB_UUKuU_vnmq9_rlT968aD1I0RUlIL2VkQLwvcUcie1HiJxtgnoBoCC70QAvD_BwE&currency=SGD&correlationId=cb791a69-345e-45fc-bf9b-60ad1c40660c&pageTypeId=1&realLanguageId=1&languageId=1&origin=SG&cid=1891471&tag=ed790da4-e0f6-a4cf-ca18-d0d6a5da36d0&userId=32c57016-f09c-4e0c-8ec6-9f1c357103ff&whitelabelid=1&loginLvl=0&storefrontId=3&currencyId=5&currencyCode=SGD&htmlLanguage=en-us&cultureInfoName=en-us&memberId=687750&machineName=sg-acmweb-6009&trafficGroupId=5&sessionId=w4sretg2mmmjtaiyiulxwzle&trafficSubGroupId=122&aid=82361&useFullPageLogin=true&cttp=4&isRealUser=true&mode=production&checkIn=${dateFromTo[0]}&checkOut=${dateFromTo[1]}&rooms=1&adults=2&children=0&priceCur=SGD&los=1&textToSearch=phuket&productType=-1&travellerType=1&familyMode=off`;
        // https://www.agoda.com/pages/agoda/default/page_textSearchResult.aspx?guid=c0e31d82-f4a7-41da-a549-36f022338da9&asq=u2qcKLxwzRU5NDuxJ0kOF3T91go8JoYYMxAgy8FkBH1BN0lGAtYH25sdXoy34qb9GrWZhohgXmCuLYV%2BITP%2FJCngfH5%2Fw65HTY0%2BUM7O42wkWmKoK2HN0CnqJy4TfgSKCpAzJy%2BIPcIhZIlXClDyyv4RjvMET4syZQfYkxvogSk%3D&tick=637900377756&txtuuid=c0e31d82-f4a7-41da-a549-36f022338da9&locale=en-us&ckuid=32c57016-f09c-4e0c-8ec6-9f1c357103ff&prid=0&currency=SGD&correlationId=021943bb-4a21-45b5-9450-ee8bbbf660eb&pageTypeId=1&realLanguageId=1&languageId=1&origin=SG&cid=-1&userId=32c57016-f09c-4e0c-8ec6-9f1c357103ff&whitelabelid=1&loginLvl=0&storefrontId=3&currencyId=5&currencyCode=SGD&htmlLanguage=en-us&cultureInfoName=en-us&memberId=687750&machineName=sg-acmweb-6008&trafficGroupId=4&sessionId=5oqoy3gv02oioapswn0gggrw&trafficSubGroupId=4&aid=130243&useFullPageLogin=true&cttp=4&isRealUser=true&mode=production&checkIn=2022-06-16&checkOut=2022-07-21&rooms=1&adults=2&children=0&priceCur=SGD&los=35&textToSearch=sdaf&productType=-1&travellerType=1&familyMode=off
        // &checkIn=2022-06-16&checkOut=2022-07-21
        // &checkIn=${dateFromTo[0]}&checkOut=${dateFromTo[1]}
        // &textToSearch=phuket
        // &rooms=1&adults=2&children=0

        // href = https://www.booking.com/searchresults.en-gb.html?label=gen173nr-1DCAEoggI46AdIM1gEaMkBiAEBmAEJuAEXyAEM2AED6AEBiAIBqAIDuAKGwfGUBsACAdICJDIyYmI5M2I0LWU4MGEtNDFjNS04MzQ1LTBiNjFlNDRhMzEyYdgCBOACAQ&lang=en-gb&sid=b4b561fc7e80353e9c460572d63acba0&sb=1&sb_lp=1&src=index&src_elem=sb&error_url=https%3A%2F%2Fwww.booking.com%2Findex.en-gb.html%3Flabel%3Dgen173nr-1DCAEoggI46AdIM1gEaMkBiAEBmAEJuAEXyAEM2AED6AEBiAIBqAIDuAKGwfGUBsACAdICJDIyYmI5M2I0LWU4MGEtNDFjNS04MzQ1LTBiNjFlNDRhMzEyYdgCBOACAQ%26sid%3Db4b561fc7e80353e9c460572d63acba0%26sb_price_type%3Dtotal%26%26&ss=DSARA+SENTRAL%2C+Sungai+Buluh%2C+Selangor%2C+Malaysia&is_ski_area=0&checkin_year=2022&checkin_month=6&checkin_monthday=13&checkout_year=2022&checkout_month=6&checkout_monthday=18&group_adults=2&group_children=0&no_rooms=1&b_h4u_keep_filters=&from_sf=1&ss_raw=dsfa&ac_position=0&ac_langcode=en&ac_click_type=b&_t=CAAAAIB7ImZmIjoxfQ&dest_id=4952353&dest_type=hotel&place_id_lat=3.1993709&place_id_lon=101.57765&search_pageview_id=d0fd3743e8500116&search_selected=true
        // href = https://www.booking.com/searchresults.en-gb.html?label=gen173nr-1DCAEoggI46AdIM1gEaMkBiAEBmAEJuAEXyAEM2AED6AEBiAIBqAIDuAKGwfGUBsACAdICJDIyYmI5M2I0LWU4MGEtNDFjNS04MzQ1LTBiNjFlNDRhMzEyYdgCBOACAQ&lang=en-gb&sid=b4b561fc7e80353e9c460572d63acba0&sb=1&sb_lp=1&src=index&src_elem=sb&error_url=https%3A%2F%2Fwww.booking.com%2Findex.en-gb.html%3Flabel%3Dgen173nr-1DCAEoggI46AdIM1gEaMkBiAEBmAEJuAEXyAEM2AED6AEBiAIBqAIDuAKGwfGUBsACAdICJDIyYmI5M2I0LWU4MGEtNDFjNS04MzQ1LTBiNjFlNDRhMzEyYdgCBOACAQ%26sid%3Db4b561fc7e80353e9c460572d63acba0%26sb_price_type%3Dtotal%26%26&ss=DSARA+SENTRAL%2C+Sungai+Buluh%2C+Selangor%2C+Malaysia&is_ski_area=0&checkin_year=2022&checkin_month=6&checkin_monthday=13&checkout_year=2022&checkout_month=6&checkout_monthday=18&group_adults=2&group_children=0&no_rooms=1&b_h4u_keep_filters=&from_sf=1&ss_raw=dsfa&ac_position=0&ac_langcode=en&ac_click_type=b&_t=CAAAAIB7ImZmIjoxfQ&dest_id=4952353&dest_type=hotel&place_id_lat=3.1993709&place_id_lon=101.57765&search_pageview_id=d0fd3743e8500116&search_selected=true
        href =
          `https://www.booking.com/searchresults.en-gb.html?label=gen173nr-1DCAEoggI46AdIM1gEaMkBiAEBmAEJuAEXyAEM2AED6AEBiAIBqAIDuAKGwfGUBsACAdICJDIyYmI5M2I0LWU4MGEtNDFjNS04MzQ1LTBiNjFlNDRhMzEyYdgCBOACAQ` +
          `&lang=en-gb&sid=b4b561fc7e80353e9c460572d63acba0&sb=1&sb_lp=1&src=index&src_elem=sb&error_url=https%3A%2F%2Fwww.booking.com%2Findex.en-gb.html%3Flabel%3Dgen173nr-1DCAEoggI46AdIM1gEaMkBiAEBmAEJuAEXyAEM2AED6AEBiAIBqAIDuAKGwfGUBsACAdICJDIyYmI5M2I0LWU4MGEtNDFjNS04MzQ1LTBiNjFlNDRhMzEyYdgCBOACAQ%26sid%3Db4b561fc7e80353e9c460572d63acba0%26` +
          `sb_price_type%3Dtotal%26%26&ss=${params.destFromTo[0].toLowerCase()}&is_ski_area=0` +
          `&checkin_year=${params.dateFromTo[0][0]}&checkin_month=${params.dateFromTo[0][1]}&checkin_monthday=${params.dateFromTo[0][2]}&checkout_year=${params.dateFromTo[1][0]}&checkout_month=${params.dateFromTo[1][1]}&checkout_monthday=${params.dateFromTo[1][2]}` +
          `&group_adults=2&group_children=0&no_rooms=1&b_h4u_keep_filters=&from_sf=1&ss_raw=dsfa&ac_position=0&ac_langcode=en&ac_click_type=b&_t=CAAAAIB7ImZmIjoxfQ&dest_id=4952353&dest_type=hotel&place_id_lat=3.1993709` +
          `&place_id_lon=101.57765&search_pageview_id=d0fd3743e8500116&search_selected=true`;
        // &checkin_year=2022&checkin_month=6&checkin_monthday=13&checkout_year=2022&checkout_month=6&checkout_monthday=18
        // &group_adults=2&group_children=0&no_rooms=1
        // ss=DSARA+SENTRAL%2C+Sungai+Buluh%2C+Selangor%2C+Malaysia

        // href = `https://www.trivago.com/?aDateRange%5Barr%5D=2022-06-14&aDateRange%5Bdep%5D=2022-07-19&aPriceRange%5Bfrom%5D=0&aPriceRange%5Bto%5D=0&iRoomType=7&aRooms%5B0%5D%5Badults%5D=2&cpt2=16019%2F200&hasList=1&hasMap=1&bIsSeoPage=0&sortingId=1&slideoutsPageItemId=&iGeoDistanceLimit=16093&address=&addressGeoCode=&offset=0&ra=&overlayMode=`;
        // aRooms%5B0%5D%5Badults%5D=2
        // aDateRange%5Barr%5D=2022-06-14&aDateRange%5Bdep%5D=2022-07-19
        // &aPriceRange%5Bfrom%5D=0&aPriceRange%5Bto%5D=0
        return href;
      case "activity":
        // let destFromTo = ["Phuket"];
        // let destFromTo = ["Phuket"];
        href = `https://www.google.com/search?q=${params.stayDetails.company}`;
        return href;
      case "eat":
        // let destFromTo = ["Phuket"];
        href = `https://www.google.com/search?q=${params.stayDetails.company}`;
        return href;
    }
  };

  const iconButton = (idx, icon, fieldOpts, value, opts) => {
    let ThisIcon = IconsGoogle[icon];
    if (!opts?.color) opts.color = "primary";
    opts.className = "btn btn-sm " + (opts?.className ? opts.className : "");
    if (!opts?.onchange && fieldOpts?.onchange)
      opts.onClick = (e) => fieldOpts.onchange(eVal, idx, e);

    let theName = opts?.fieldName
      ? opts.fieldName
      : fieldOpts?.fieldName
      ? fieldOpts.fieldName
      : "";
    let eVal = {
      target: {
        name: theName,
        id: theName,
        value: value,
      },
    };
    return (
      <button
        // className={opts.className}
        {...opts}
        key={"legI-btn-" + icon + "-" + idx}
      >
        <span
          className="material-icons float-end"
          style={{ fontSize: "1.5em" }}
        >
          {ThisIcon}
        </span>
      </button>
    );
  };

  const showLinkedButton = (fieldSet, idx) => {
    let value = readData(leg, fieldSet.data);

    return (
      <Fragment>
        {leg && value
          ? iconButton(
              idx,
              "Linked",
              defaultDef("linkToLast", onLegChange),
              false,
              {}
            )
          : iconButton(
              idx,
              "Unlinked",
              defaultDef("linkToLast", onLegChange),
              true,
              {
                color: "default",
              }
            )}
      </Fragment>
    );
  };

  const showLockedButton = (fieldSet, idx) => {
    let value = readData(leg, fieldSet.data);

    return (
      <Fragment>
        {leg && value
          ? iconButton(
              idx,
              "Locked",
              defaultDef("locked", onLegChange),
              false,
              {}
            )
          : iconButton(
              idx,
              "Unlocked",
              defaultDef("locked", onLegChange),
              true,
              {
                color: "default",
              }
            )}
      </Fragment>
    );
  };

  const showExpandButton = (bShowRow, idx, legId) => (
    <Fragment>
      {iconButton(
        idx,
        bShowRow ? "CloseLeg" : "OpenLeg",
        { onchange: showHideRow },
        legId,
        {}
      )}
    </Fragment>
  );

  const showMoreLegButton = (legId, idx) => {
    return (
      <Fragment>
        {iconButton(idx, "More", { onchange: showHideMoreLeg }, legId, {})}
      </Fragment>
    );
    // return "";
    // return iconButton(
    //   "00",
    //   "More",
    //   {
    //     onchange: () => showHideMoreLeg(legId),
    //   },
    //   legId,
    //   {}
    // );
  };
  // const showMoreLegButton = (legId, idx) => {
  //   // return "";
  //   return iconButton(
  //     "00",
  //     "More",
  //     {
  //       onchange: () => setModalLeg({ legId, idx, legTypeLower }),
  //     },
  //     legId,
  //     {
  //       "data-bs-toggle": "modal",
  //       "data-bs-target": "#showLegDetailModal",
  //       "data-id": legId,
  //       "data-bs-whatever": legId,
  //     }
  //   );
  // };

  return (
    <Fragment>
      {showFields(
        idx,
        !legTypeLower ? "transport" : legTypeLower,
        fieldDef(legTypeLower, onLegChange),
        bShowRow
      )}
    </Fragment>
  );
};

export default LegItem;
