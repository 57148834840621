export const defaultHoliday = {
  members: [],
  edit: [],
  view: [],
  name: "",
  description: "",
  when: { start: "", finish: "", noOfDays: "" },
  travelInsurance: {
    company: "",
    policyNo: "",
    website: "",
    otherDetails: "",
    fileLink: "",
  },
  legs: [],
  comments: "",
};

export const defaultHolidayDef = (getDef, onHolidayChange) => {
  let dDef = {
    name: {
      data: "name",
      fieldName: "name",
      fieldLabel: "name",
      type: "text",
      columns: 6,
      onchange: onHolidayChange,
      disabled: false,
    },
    description: {
      data: "description",
      fieldName: "description",
      fieldLabel: "description",
      type: "text",
      columns: 12,
      onchange: onHolidayChange,
      disabled: false,
    },
    whenStart: {
      data: "when.start",
      fieldName: "when.start",
      fieldLabel: "start",
      type: "date",
      columns: 6,
      onchange: onHolidayChange,
      disabled: false,
    },
    whenFinish: {
      data: "when.finish",
      fieldName: "when.finish",
      fieldLabel: "finish",
      type: "date",
      columns: 6,
      onchange: onHolidayChange,
      disabled: false,
    },
    whenNoOfDays: {
      data: "when.noOfDays",
      fieldName: "when.noOfDays",
      fieldLabel: "days",
      type: "text",
      columns: 2,
      onchange: onHolidayChange,
      disabled: false,
    },
    travelInsCompany: {
      data: "travelInsurance.company",
      fieldName: "travelInsurance.company",
      fieldLabel: "company",
      type: "text",
      columns: 6,
      onchange: onHolidayChange,
      disabled: false,
    },
    travelInsPolicyNo: {
      data: "travelInsurance.policyNo",
      fieldName: "travelInsurance.policyNo",
      fieldLabel: "policy no",
      type: "text",
      columns: 6,
      onchange: onHolidayChange,
      disabled: false,
    },
    travelInsWebsite: {
      data: "travelInsurance.website",
      fieldName: "travelInsurance.website",
      fieldLabel: "website",
      type: "text",
      columns: 6,
      onchange: onHolidayChange,
      disabled: false,
    },
    travelInsOtherInfo: {
      data: "travelInsurance.otherDetails",
      fieldName: "travelInsurance.otherDetails",
      fieldLabel: "other info",
      type: "text",
      columns: 12,
      onchange: onHolidayChange,
      disabled: false,
    },
    travelInsurancefileLink: {
      data: "travelInsurance.fileLink",
      fieldName: "travelInsurance.fileLink",
      fieldLabel: "file",
      type: "text",
      columns: 12,
      onchange: onHolidayChange,
      disabled: false,
    },
  };
  return dDef[getDef];
};

export const fieldDefHoliday = (getDef, onHolidayChange) => {
  let fDef = {
    holiday: {
      fieldSet: [
        [{ field: "name" }],
        [{ field: "description" }],
        [
          { field: "whenStart" },
          { field: "whenNoOfDays" },
          { field: "whenFinish" },
        ],
        [
          { field: "travelInsCompany" },
          { field: "travelInsPolicyNo" },
          { field: "travelInsWebsite" },
        ],
        [{ field: "travelInsOtherInfo" }],
        [{ field: "travelInsurancefileLink" }],
      ],
      fieldsMore: [
        [{ field: "name" }],
        [{ field: "description" }],
        [
          { field: "whenStart" },
          { field: "whenNoOfDays" },
          { field: "whenFinish" },
        ],
        [
          { field: "travelInsCompany" },
          { field: "travelInsPolicyNo" },
          { field: "travelInsWebsite" },
        ],
        [{ field: "travelInsOtherInfo" }],
        [{ field: "travelInsurancefileLink" }],
      ],
      fieldsDef: {
        name: {
          ...defaultHolidayDef("name", onHolidayChange),
        },
        description: {
          ...defaultHolidayDef("description", onHolidayChange),
        },
        whenStart: {
          ...defaultHolidayDef("whenStart", onHolidayChange),
        },
        whenNoOfDays: {
          ...defaultHolidayDef("whenNoOfDays", onHolidayChange),
        },
        whenFinish: {
          ...defaultHolidayDef("whenFinish", onHolidayChange),
        },
        travelInsCompany: {
          ...defaultHolidayDef("travelInsCompany", onHolidayChange),
        },
        travelInsPolicyNo: {
          ...defaultHolidayDef("travelInsPolicyNo", onHolidayChange),
        },
        travelInsWebsite: {
          ...defaultHolidayDef("travelInsWebsite", onHolidayChange),
        },
        travelInsOtherInfo: {
          ...defaultHolidayDef("travelInsOtherInfo", onHolidayChange),
        },
        travelInsurancefileLink: {
          ...defaultHolidayDef("travelInsurancefileLink", onHolidayChange),
        },
      },
      background: "bg-info",
    },
  };
  return fDef[getDef];
};
