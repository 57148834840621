import { dateFormats } from "../../utils/common";

export const defaultLeg = {
  _id: "new-1",
  members: [],
  view: [],
  edit: [],
  legType: "Transport",
  subType: "",
  name: "",
  description: "",
  from: {
    dateTime: "",
    city: "",
    country: "",
  },
  noOfDays: "",
  linkToLast: true,
  bufferFromLast: 0,
  to: { dateTime: "", city: "", country: "" },
  stayDetails: {
    company: "",
    reservationNo: "",
    nights: "",
    cost: "",
    ccy: "",
  },
  travelDetails: {
    company: "",
    reservationNo: "",
    vehicleNo: "",
    cost: null,
    ccy: "",
    subPart: false,
    subPartId: "",
  },
  cost: {
    total: "",
    ccy: "",
    partCost: false,
  },
};

export const defaultDef = (getDef, onLegChange) => {
  let dDef = {
    status: {
      // field: leg?.status,
      data: "status",
      fieldName: "status",
      fieldLabel: "",
      type: "text",
      columns: 5,
      // size: "150px",
      // size: "95%",
      onchange: onLegChange,
      disabled: false,
    },
    locked: {
      // field: leg?.locked,
      data: "locked",
      fieldName: "locked",
      fieldLabel: "",
      type: "checkbox",
      columns: 1,
      // size: "150px",
      // size: "95%",
      onchange: onLegChange,
      disabled: false,
    },
    fromCity: {
      // field: from?.city,
      data: "from.city",
      fieldName: "from.city",
      fieldLabel: "from",
      type: "text",
      columns: 6,
      // size: "150px",
      // size: "95%",
      onchange: onLegChange,
      disabled: false,
    },
    toCity: {
      // field: to?.city,
      data: "to.city",
      fieldName: "to.city",
      fieldLabel: "to",
      type: "text",
      columns: 6,
      // size: "150px",
      // size: "95%",
      onchange: onLegChange,
      disabled: false,
    },
    fromDateTime: {
      // field: from?.dateTime,
      data: "from.dateTime",
      fieldName: "from.dateTime",
      fieldLabel: "start",
      type: "date",
      columns: 5,
      format: "summaryDateTime",
      onchange: onLegChange,
      disabled: false,
      options: ["showDatePicker"],
    },
    noOfDays: {
      // field: leg?.noOfDays,
      data: "noOfDays",
      fieldName: "noOfDays",
      fieldLabel: "days",
      type: "text",
      columns: 2,
      // size: "100%",
      // size: "45px",
      onchange: onLegChange,
      disabled: false,
    },
    toDateTime: {
      // field: to?.dateTime,
      data: "to.dateTime",
      fieldName: "to.dateTime",
      fieldLabel: "finish",
      type: "date",
      columns: 5,
      format: "summaryDateTime",
      onchange: onLegChange,
      disabled: false,
      options: ["showDatePicker"],
    },
    costTotal: {
      // field: cost?.total,
      data: "cost.total",
      fieldName: "cost.total",
      fieldLabel: "cost",
      type: "float",
      columns: 4,
      format: "currency",
      size: "90%",
      // size: "100px",
      onchange: onLegChange,
      disabled: false,
    },
    stayDetailsCompany: {
      // field: stayDetails?.company,
      data: "stayDetails.company",
      fieldName: "stayDetails.company",
      fieldLabel: "hotel",
      type: "text",
      columns: 6,
      // size: "150px",
      // size: "95%",
      onchange: onLegChange,
      disabled: false,
    },
    stayDetailsReservationNo: {
      // field: stayDetails?.reservationNo,
      data: "stayDetails.reservationNo",
      fieldName: "stayDetails.reservationNo",
      fieldLabel: "booking no.",
      type: "text",
      columns: 6,
      // size: "150px",
      // size: "95%",
      onchange: onLegChange,
      disabled: false,
    },
    stayDetailsRoomNo: {
      data: "stayDetails.roomNo",
      fieldName: "stayDetails.roomNo",
      fieldLabel: "room no.",
      type: "text",
      columns: 6,
      onchange: onLegChange,
      disabled: false,
    },
    stayDetailsRoomType: {
      data: "stayDetails.roomType",
      fieldName: "stayDetails.roomType",
      fieldLabel: "room type.",
      type: "text",
      columns: 6,
      onchange: onLegChange,
      disabled: false,
    },
    stayDetailsLocation: {
      data: "stayDetails.location",
      fieldName: "stayDetails.location",
      fieldLabel: "location",
      type: "text",
      columns: 6,
      onchange: onLegChange,
      disabled: false,
    },
    stayDetailsContacts: {
      data: "stayDetails.contacts",
      fieldName: "stayDetails.contacts",
      fieldLabel: "contacts",
      type: "text",
      columns: 6,
      onchange: onLegChange,
      disabled: false,
    },
    travelDetailsCompany: {
      // field: travelDetails?.company,
      data: "travelDetails.company",
      fieldName: "travelDetails.company",
      fieldLabel: "airline",
      type: "text",
      columns: 5,
      // size: "150px",
      // size: "95%",
      onchange: onLegChange,
      disabled: false,
    },
    travelDetailsVehicleNo: {
      // field: travelDetails?.vehicleNo,
      data: "travelDetails.vehicleNo",
      fieldName: "travelDetails.vehicleNo",
      fieldLabel: "flight no.",
      type: "text",
      columns: 3,
      // size: "150px",
      // size: "95%",
      onchange: onLegChange,
      disabled: false,
    },
    travelDetailsReservationNo: {
      // field: travelDetails?.reservationNo,
      data: "travelDetails.reservationNo",
      fieldName: "travelDetails.reservationNo",
      fieldLabel: "booking ref.",
      type: "text",
      columns: 4,
      // size: "150px",
      // size: "95%",
      onchange: onLegChange,
      disabled: false,
    },
    travelDetailsSubPart: {
      // field: travelDetails?.subPart,
      data: "travelDetails.subPart",
      fieldName: "travelDetails.subPart",
      fieldLabel: "Return?",
      type: "checkbox",
      columns: 1,
      // size: "150px",
      // size: "95%",
      onchange: onLegChange,
      disabled: false,
    },
    travelDetailsAddress: {
      data: "travelDetails.address",
      fieldName: "travelDetails.address",
      fieldLabel: "address",
      type: "text",
      columns: 6,
      onchange: onLegChange,
      disabled: false,
    },
    travelDetailsLocation: {
      data: "travelDetails.location",
      fieldName: "travelDetails.location",
      fieldLabel: "location",
      type: "text",
      columns: 6,
      onchange: onLegChange,
      disabled: false,
    },
    travelDetailsContacts: {
      data: "travelDetails.contacts",
      fieldName: "travelDetails.contacts",
      fieldLabel: "contacts",
      type: "text",
      columns: 6,
      onchange: onLegChange,
      disabled: false,
    },
    linkToLast: {
      // field: leg?.linkToLast,
      data: "linkToLast",
      fieldName: "linkToLast",
      fieldLabel: "",
      type: "checkbox",
      columns: 1,
      // size: "150px",
      // size: "95%",
      onchange: onLegChange,
      disabled: false,
    },
  };

  return dDef[getDef];
};

export const fieldDef = (getDef, onLegChange) => {
  let fDef = {
    transport: {
      fieldSet: [
        [{ field: "fromCity" }, { field: "toCity" }],
        // ["fromDateTime", "noOfDays"],
        [
          { field: "fromDateTime" },
          { field: "noOfDays" },
          { field: "toDateTime" },
        ],
        [
          // "travelDetailsSubPart",
          { field: "travelDetailsCompany" },
          { field: "travelDetailsVehicleNo" },
          { field: "travelDetailsReservationNo" },
        ],
        // ["costTotal", "travelDetailsSubPart"],
        [{ field: "costTotal" }, { field: "travelDetailsSubPart" }],
      ],
      fieldsMore: [
        [{ field: "fromCity" }, { field: "toCity" }],
        // ["fromDateTime", "noOfDays"],
        [
          { field: "fromDateTime" },
          { field: "noOfDays" },
          { field: "toDateTime" },
        ],
        [
          // "travelDetailsSubPart",
          { field: "travelDetailsCompany" },
          { field: "travelDetailsVehicleNo" },
          { field: "travelDetailsReservationNo" },
        ],
        // ["costTotal", "travelDetailsSubPart"],
        [{ field: "costTotal" }, { field: "travelDetailsSubPart" }],
      ],
      fieldsDef: {
        fromCity: {
          ...defaultDef("fromCity", onLegChange),
          type: "location",
          set: ["countries", "cities", "airports"],
        },
        toCity: {
          ...defaultDef("toCity", onLegChange),
          type: "location",
          set: ["countries", "cities", "airports"],
        },
        fromDateTime: {
          ...defaultDef("fromDateTime", onLegChange),
          fieldLabel: "depart",
        },
        noOfDays: {
          ...defaultDef("noOfDays", onLegChange),
          fieldLabel: "duration",
        },
        toDateTime: {
          ...defaultDef("toDateTime", onLegChange),
          fieldLabel: "arrive",
        },
        costTotal: { ...defaultDef("costTotal", onLegChange) },
        travelDetailsSubPart: {
          ...defaultDef("travelDetailsSubPart", onLegChange),
        },
        travelDetailsCompany: {
          ...defaultDef("travelDetailsCompany", onLegChange),
        },
        travelDetailsVehicleNo: {
          ...defaultDef("travelDetailsVehicleNo", onLegChange),
        },
        travelDetailsReservationNo: {
          ...defaultDef("travelDetailsReservationNo", onLegChange),
        },
      },
      background: "bg-info",
    },
    stay: {
      fieldSet: [
        [{ field: "fromCity" }, { field: "stayDetailsCompany" }],
        [
          { field: "fromDateTime" },
          { field: "noOfDays" },
          { field: "toDateTime" },
        ],
        [{ field: "costTotal" }, { field: "stayDetailsReservationNo" }],
      ],
      fieldsMore: [
        [
          { field: "fromCity", columns: 6 },
          { field: "stayDetailsCompany", columns: 6 },
        ],
        [
          { field: "fromDateTime" },
          { field: "noOfDays" },
          { field: "toDateTime" },
        ],
        [{ field: "costTotal" }],
        [
          { field: "stayDetailsReservationNo", columns: 6 },
          { field: "stayDetailsLocation", columns: 6 },
        ],
      ],
      fieldsDef: {
        fromCity: {
          ...defaultDef("fromCity", onLegChange),
          fieldLabel: "city",
          type: "location",
          set: ["cities"],
        },
        stayDetailsCompany: {
          ...defaultDef("stayDetailsCompany", onLegChange),
        },
        fromDateTime: {
          ...defaultDef("fromDateTime", onLegChange),
          fieldLabel: "check-in",
          format: "summaryDate",
        },
        noOfDays: {
          ...defaultDef("noOfDays", onLegChange),
          fieldLabel: "nights",
        },
        toDateTime: {
          ...defaultDef("toDateTime", onLegChange),
          fieldLabel: "check-out",
          format: "summaryDate",
        },
        costTotal: { ...defaultDef("costTotal", onLegChange) },
        stayDetailsReservationNo: {
          ...defaultDef("stayDetailsReservationNo", onLegChange),
        },
        stayDetailsLocation: {
          ...defaultDef("stayDetailsLocation", onLegChange),
        },
      },
      background: "bg-primary",
    },
    activity: {
      fieldSet: [
        [{ field: "fromCity" }, { field: "stayDetailsCompany" }],
        [
          { field: "fromDateTime" },
          { field: "noOfDays" },
          { field: "toDateTime" },
        ],
        [{ field: "costTotal" }, { field: "stayDetailsReservationNo" }],
      ],
      fieldsMore: [
        [{ field: "fromCity" }, { field: "stayDetailsCompany" }],
        [
          { field: "fromDateTime" },
          { field: "noOfDays" },
          { field: "toDateTime" },
        ],
        [{ field: "costTotal" }, { field: "stayDetailsReservationNo" }],
      ],
      fieldsDef: {
        fromCity: {
          ...defaultDef("fromCity", onLegChange),
          fieldLabel: "city",
          type: "location",
          set: ["cities"],
        },
        stayDetailsCompany: {
          ...defaultDef("stayDetailsCompany", onLegChange),
          fieldLabel: "company",
        },
        fromDateTime: {
          ...defaultDef("fromDateTime", onLegChange),
          fieldLabel: "start",
          format: "summaryDateTime",
        },
        noOfDays: {
          ...defaultDef("noOfDays", onLegChange),
          fieldLabel: "pax",
        },
        toDateTime: {
          ...defaultDef("toDateTime", onLegChange),
          fieldLabel: "time",
          format: "summaryTime",
        },
        costTotal: { ...defaultDef("costTotal", onLegChange) },
        stayDetailsReservationNo: {
          ...defaultDef("stayDetailsReservationNo", onLegChange),
        },
      },
      background: "bg-primary",
    },
    eat: {
      fieldSet: [
        [{ field: "fromCity" }, { field: "stayDetailsCompany" }],
        [
          { field: "fromDateTime" },
          { field: "noOfDays" },
          { field: "toDateTime" },
        ],
        [{ field: "costTotal" }, { field: "stayDetailsReservationNo" }],
      ],
      fieldsMore: [
        [{ field: "fromCity" }, { field: "stayDetailsCompany" }],
        [
          { field: "fromDateTime" },
          { field: "noOfDays" },
          { field: "toDateTime" },
        ],
        [{ field: "costTotal" }, { field: "stayDetailsReservationNo" }],
      ],
      fieldsDef: {
        fromCity: {
          ...defaultDef("fromCity", onLegChange),
          fieldLabel: "city",
          type: "location",
          set: ["cities"],
        },
        stayDetailsCompany: {
          ...defaultDef("stayDetailsCompany", onLegChange),
          fieldLabel: "restaurant",
        },
        fromDateTime: {
          ...defaultDef("fromDateTime", onLegChange),
          fieldLabel: "date",
          format: "summaryDateTime",
        },
        noOfDays: {
          ...defaultDef("noOfDays", onLegChange),
          fieldLabel: "pax",
        },
        toDateTime: {
          ...defaultDef("toDateTime", onLegChange),
          fieldLabel: "time",
          format: "summaryTime",
        },
        costTotal: { ...defaultDef("costTotal", onLegChange) },
        stayDetailsReservationNo: {
          ...defaultDef("stayDetailsReservationNo", onLegChange),
        },
      },
      background: "bg-primary",
    },
  };
  return fDef[getDef];
};

export const fieldDefTypeSelect = (onTypeChange) => ({
  fieldLabel: "Type",
  data: "legType",
  fieldName: "legType",
  field: "legType",
  onchange: onTypeChange,
});

export const defaultTotals = {
  from: { dateTime: "" },
  to: { dateTime: "" },
  noOfDays: "",
  cost: "",
  ccy: "",
};

export const totalsDef = (onTotalsChange) => {
  return [
    {
      name: "fromDateTime",
      data: "from.dateTime",
      fieldName: "totals.from.dateTime",
      fieldLabel: "start",
      type: "date",
      cols: 4,
      format: dateFormats.datePickerLuxon,
      onchange: onTotalsChange,
      disabled: true,
    },
    {
      name: "noOfDays",
      data: "noOfDays",
      fieldName: "totals.noOfDays",
      fieldLabel: "days",
      type: "number",
      cols: 2,
      onchange: onTotalsChange,
      disabled: true,
    },
    {
      name: "toDateTime",
      data: "to.dateTime",
      fieldName: "totals.to.dateTime",
      fieldLabel: "finish",
      type: "date",
      cols: 4,
      format: dateFormats.datePickerLuxon,
      onchange: onTotalsChange,
      disabled: true,
    },
    {
      name: "totalCost",
      data: "cost",
      fieldName: "totals.cost",
      fieldLabel: "total",
      type: "float",
      cols: 2,
      format: "currency",
      onchange: onTotalsChange,
      disabled: true,
    },
  ];
};
