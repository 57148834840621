import React, { useContext } from "react";

import AuthContext from "../../context/auth/authContext";
import HolidayContext from "../../context/holiday/holidayContext";

const MyHolidayList = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const holidayContext = useContext(HolidayContext);
  const { holidays, setCurrentHoliday } = holidayContext;

  let rs = "";
  let statusTypes = {
    mine: { title: "mine", color: "success" },
    shared: { title: "shared", color: "primary" },
  };
  let k = `legList-holidays-list-`;
  return (
    <div className="container mt-2">
      <h4>My Holidays</h4>
      {holidays?.length ? (
        holidays.map((holiday, idx) => {
          rs = rs === "" ? "bg-light" : "";
          let whose = holiday.user !== user._id ? "shared" : "mine";
          return (
            <div className={"row mb-2 " + rs} key={k + `row-` + idx}>
              <div className="col-8" key={k + `col-1-` + idx}>
                {holiday?.name ? holiday?.name : "No Name Holiday"}
              </div>
              <div className="col-2" key={k + `col-2-` + idx}>
                <div
                  className="d-flex justify-content-center"
                  key={k + `col-2-flex-` + idx}
                >
                  <div
                    className="align-self-center"
                    key={k + `col-align-` + idx}
                  >
                    <span
                      key={k + `col-2-status-` + idx}
                      className={
                        `badge rounded-pill bg-` + statusTypes[whose].color
                      }
                    >
                      {statusTypes[whose].title}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-2" key={k + `col-3-` + idx}>
                <div className="float-end" key={k + `col-2-float-` + idx}>
                  <button
                    className="btn btn-sm btn-primary"
                    key={k + `btn-` + idx}
                    onClick={(e) => setCurrentHoliday(holiday)}
                  >
                    VIEW
                  </button>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p>No holidays, please create one</p>
      )}
    </div>
  );
};

export default MyHolidayList;
