import React, { useReducer } from "react";
import axios from "axios";
import LegContext from "./legContext";
import legReducer from "./legReducer";

import {
  GET_LEGS,
  CLEAR_LEGS,
  GET_LEG,
  CLEAR_LEG,
  ADD_LEG,
  // DELETE_LEG,
  // UPDATE_LEG,
  // FILTER_LEGS,
  SET_CURRENT_LEG,
  CLEAR_CURRENT_LEG,
  // CLEAR_FILTER_LEG,
  LEG_ERROR,
  // CLEAR_ERRORS,
} from "../typesHolidays";

const LegState = (props) => {
  const initialState = {
    legs: null,
    leg: null,
    current: null,
    loading: true,
    error: null,
  };

  const [state, dispatch] = useReducer(legReducer, initialState);

  // Load Holiday Legs
  const getLegs = async (holiday) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      if (!holiday?._id) {
        dispatch({
          type: CLEAR_LEGS,
          payload: null,
        });
      } else {
        const res = await axios.post("/api/holidays/legs/get", holiday, config);

        if (res.data) {
          // send user to reducer and mark as authenticated
          dispatch({
            type: GET_LEGS,
            payload: res.data,
          });
        } else {
          // send user to reducer and mark as authenticated
          dispatch({
            type: CLEAR_LEGS,
            payload: res.data,
          });
        }
      }
    } catch (err) {
      dispatch({ type: LEG_ERROR });
    }
  };

  // Load Holiday Legs
  const getLeg = async (holidayId, legId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      if (!holidayId || !legId) {
        dispatch({
          type: CLEAR_LEG,
          payload: null,
        });
      } else {
        const res = await axios.post(
          "/api/holidays/leg/get",
          { holidayId: holidayId, legId: legId },
          config
        );

        if (res.data) {
          // send user to reducer and mark as authenticated
          dispatch({
            type: GET_LEG,
            payload: res.data,
          });
        } else {
          // send user to reducer and mark as authenticated
          dispatch({
            type: CLEAR_LEG,
            payload: res.data,
          });
        }
      }
    } catch (err) {
      dispatch({ type: LEG_ERROR });
    }
  };

  // Login User
  const addLeg = async (leg) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post("/api/legs/add", leg, config);

      // send to reducer to log token in local storage
      //   and mark as authenticated
      dispatch({
        type: ADD_LEG,
        payload: res.data,
      });

      // // load user and return user details
      // loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: LEG_ERROR,
      });
    }
  };

  const clearLegs = () => {
    dispatch({ type: CLEAR_LEGS });
  };

  const clearLeg = () => {
    dispatch({ type: CLEAR_LEG });
  };

  const setCurrentLeg = (data) => {
    dispatch({ type: SET_CURRENT_LEG, payload: data });
  };

  const clearCurrentLeg = () => {
    dispatch({ type: CLEAR_CURRENT_LEG });
  };

  return (
    <LegContext.Provider
      value={{
        getLegs,
        clearLegs,
        addLeg,
        legs: state.legs,
        getLeg,
        clearLeg,
        leg: state.leg,
        setCurrentLeg,
        clearCurrentLeg,
        current: state.current,
        loading: state.loading,
      }}
    >
      {props.children}
    </LegContext.Provider>
  );
};

export default LegState;
