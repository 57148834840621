import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  Fragment,
} from "react";
import ReactSelect from "react-select/creatable";
import HolidayContext from "../../context/holiday/holidayContext";

const LocationFilter = ({ opts, value, scope }) => {
  const holidayContext = useContext(HolidayContext);
  const { cities, countries, airports, getLocationReferenceData } =
    holidayContext;

  const filter = useRef("");
  const [filterSet, setFilterSet] = useState({ filters: [] });

  let filters = [
    {
      name: "countries",
      dataset: countries,
      fields: ["CLDR display name"],
      values: "ISO3166-1-Alpha-2",
    },
    {
      name: "airports",
      dataset: airports,
      fields: ["name", "iata_code"],
      values: "iata_code",
    },
    {
      name: "cities",
      dataset: cities,
      fields: ["name"],
      values: "name",
    },
  ];

  const getFilterList = (filters, value) => {
    let list = [];

    for (let filter of filters) {
      // run through each of teh filters
      if (scope.includes(filter.name) && filter?.dataset?.length > 0) {
        // check if the dataset has records
        for (let field of filter.fields) {
          // run throug each field to be included in the list
          for (let data of filter.dataset) {
            // run through each record of the data set
            let v = data[field];
            // if (v && !list.includes(v)) list.push({label: v, value: filter});
            if (v && !list.includes(v))
              list.push({ label: v, value: data[filter.values] });
          }
        }
      }
    }
    return list;

    // let filterList = list.map((item) => {
    //   return { label: item, value: item };
    // });
    // return filterList;
  };

  const dataHasRecords = (filterSet) => {
    for (let i = 0; i < filterSet.length; i++) {
      if (filterSet?.[i]?.dataset?.length > 0) return true;
    }
    return false;
  };

  useEffect(() => {
    if (dataHasRecords(filters))
      setFilterSet({ filters: getFilterList(filters) });
    //eslint-disable-next-line
  }, [countries, cities, airports]);

  // const onChange = (e, a) => {
  //   if (a.action === "create-option") {
  //     setFilterSet({
  //       filters: [...filterSet.filters, e[e.length - 1]],
  //     });
  //   }

  //   // clearFilter();
  //   if (e !== null) {
  //     // filterBalances(e);
  //   }
  // };

  const handleInputChange = (e, a) => {
    if (e.length > 2) {
      // console.log(e);
      getLocationReferenceData(e, scope);
      // setFilterSet({ filters: getFilterList(filters, e) });
    }
  };

  return (
    <Fragment>
      <ReactSelect
        // isMulti
        // isClearable
        {...opts}
        className="form-control form-control-sm"
        ref={filter}
        options={filterSet.filters}
        value={value}
        // value={filterSet.filters.filter((option) => option.label === value)}
        // onChange={onChange}
        onInputChange={handleInputChange}
      />
    </Fragment>
  );
};

export default LocationFilter;
