import React, { Fragment, useContext, useEffect, useState } from "react";

import HolidayContext from "../../context/holiday/holidayContext";
import HolidayDropDown from "../holidays/HolidayDropDown";
import AuthContext from "../../context/auth/authContext";
import AlertContext from "../../context/alert/alertContext";
import LegContext from "../../context/leg/legContext";

import generatePDF from "./reportGenerator";
import ShareIt from "./ShareIt";
import LegsList from "./LegList";

import {
  icon,
  checkIfMobile,
  iterateFieldSets,
  getValidDate,
  formatValue,
  dateFormats,
} from "../../utils/common";
import Spinner from "../layout/Spinner";
import { defaultHoliday, fieldDefHoliday } from "./holidayDefinition";
import { defaultLeg } from "./legDefinition";

const LegsMain = () => {
  const holidayContext = useContext(HolidayContext);
  const {
    addHoliday,
    updateHoliday,
    currentHoliday,
    updateMembers,
    error,
    clearHolidayErrors,
    clearCurrentHoliday,
    getEditList,
    // editList,
    clearEditList,
    // clearCurrentHoliday,
    // getLocationReferenceData,
    // countries,
    // cities,
    // airports,
  } = holidayContext;

  const authContext = useContext(AuthContext);
  const { users, user } = authContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const legContext = useContext(LegContext);
  const { legs, getLegs, clearLegs } = legContext;

  const [editHoliday, setEditHoliday] = useState({ edit: false });
  const [holiday, setHoliday] = useState(defaultHoliday);
  const [legsNew, setLegsNew] = useState([defaultLeg]);

  // const [showShareMenu, setShowShareMenu] = React.useState(null);

  const IS_MOBILE = checkIfMobile();

  useEffect(() => {
    if (error === "Record updated") {
      setAlert(error, "success");
      clearHolidayErrors();
      clearEditList();
    }
    if (currentHoliday?._id) {
      setHoliday(currentHoliday);
      getLegs(currentHoliday);
      getEditList(currentHoliday._id);
    } else {
      setHoliday(defaultHoliday);
      // clearLegs();
    }

    // eslint-disable-next-line
  }, [currentHoliday]);

  if (legContext.loading || holidayContext.loading) <Spinner />;

  // let buttonSize = { maxWidth: "30px", maxHeight: "30px" };

  // const onHolidayChange = (e) => {
  //   let fld = e.target.name;
  //   let val = e.target.value;

  //   setHoliday({ ...holiday, [fld]: val });
  // };

  const onHolidayChange = (e) => {
    console.log(e);
    let fld = e.target.name;
    let newHoliday = { ...holiday };
    let value;

    if (e?.target?.type === "jsdate") {
      value = getValidDate(e?.target?.value[0], "jsdate");
      // value = getValidDate(e?.target?.value[0], dateFormats.datePickerLuxon);
      value = formatValue("date", value, dateFormats.fromDB);
    } else {
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    }

    if (fld.includes(".")) {
      let flds = fld.split(".");
      if (!newHoliday[flds[0]]) newHoliday[flds[0]] = defaultHoliday[flds[0]];
      newHoliday[flds[0]][flds[1]] = value;
    } else {
      newHoliday[fld] = value;
    }

    setHoliday(newHoliday);
  };

  const saveTheHoliday = (e) => {
    e.preventDefault();
    if (holiday?._id && !holiday._id.includes("new")) {
      updateHoliday(holiday, legsNew);
    } else {
      addHoliday(holiday, legsNew);
    }
  };

  let btns = {
    back: {
      action: "back",
      title: "BACK",
      iconName: "arrow_back",
      tt: "",
      // disabled: !currentHoliday,
    },
    edit: {
      action: "edit",
      title: "",
      iconName: "edit",
      tt: !editHoliday ? "Edit Holiday" : "",
      disabled: !currentHoliday,
    },
    pdf: {
      action: "pdf",
      title: "PDF",
      iconName: "download",
      tt: !currentHoliday ? "Please select holiday" : "Download Holiday",
      disabled: !currentHoliday,
    },
    save: {
      action: "save",
      title: "SAVE",
      iconName: "save",
      tt: !currentHoliday ? "Please select holiday" : "Save Holiday",
      disabled: !currentHoliday,
    },
    "save-edit": {
      action: "save-edit",
      title: "",
      iconName: "done",
      tt: !editHoliday ? "Save Holiday" : "",
    },
    cancel: {
      action: "cancel",
      title: "",
      iconName: "close",
      tt: !editHoliday ? "Cancel editing without saving" : "",
    },
  };

  const btnFunctions = (...params) => {
    switch (params[1]) {
      case "back":
        clearLegs();
        clearCurrentHoliday();
        break;
      case "edit":
        if (!currentHoliday) return;
        setEditHoliday(params[2]);
        break;
      case "cancel":
        setEditHoliday({ edit: false });
        break;
      case "save-edit":
        setEditHoliday({ edit: false });
        saveTheHoliday(params[0]);
        break;
      case "save":
        if (!currentHoliday) return;
        saveTheHoliday(params[0]);
        break;
      case "pdf":
        if (!currentHoliday) return;
        generatePDF(currentHoliday, params[2].legs);
        break;
      default:
    }
  };

  const showSaveEditButtons = () => {
    return (
      <div className="m-0 p-0">
        {/* <div className="float-start" key={"legs-btns-save-stopedit"}> */}
        {showButton(btns["save-edit"], {})}
        {showButton(btns.cancel, {})}
      </div>
    );
  };

  const showButton = (btn, params) => {
    let k = "legs-btn-" + btn.action;
    let isIcon = !btn.title || IS_MOBILE;

    return (
      <span
        key={k + "-tooltip"}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={btn.tt}
        className="m-0 p-0"
      >
        <button
          type="button"
          key={k + "-btn"}
          className={`btn btn-sm ${isIcon ? "mx-1 p-0" : "mx-1 btn-primary"}`}
          style={{ borderWidth: "0px" }}
          onClick={(e) => btnFunctions(e, btn.action, params)}
          disabled={btn?.disabled !== undefined ? btn.disabled : false}
        >
          {!IS_MOBILE && btn?.title !== ""
            ? btn.title
            : icon(btn.iconName, "1.5ems", k)}
        </button>
      </span>
    );
  };

  const onShareItClick = (e, action, params) => {
    // e?.preventDefault();
    updateMembers(action, params);
  };

  const shareIt = (idx) => (
    <ShareIt
      IS_MOBILE={IS_MOBILE}
      idx={idx}
      currentRecord={currentHoliday}
      toolTip={
        !currentHoliday
          ? "Please select holiday before sharing."
          : currentHoliday._id === "new"
          ? "Please save holiday before sharing."
          : "Share Holiday"
      }
      editList={currentHoliday?.edit}
      userList={users}
      user={user}
      onShareItClick={onShareItClick}
      // inUserList={inUserList}
      // ddListLink={ddListLink}
    />
  );

  // shows all the data fields from the fieldsection
  // which defines the field grouping
  const showDataFields = () => {
    let styls = { col: "col-auto g-1", row: "row" };
    let k = ["holiday"].join("-");

    // let sections = fieldDef[legTypeLower];
    let sections = fieldDefHoliday("holiday", onHolidayChange);
    let secName = "fieldSet";
    // let secName = bShowMoreLeg ? "fieldsMore" : "fieldSet";

    return (
      <Fragment>
        {iterateFieldSets(holiday, sections, secName, styls, k, 0)}
      </Fragment>
    );
  };

  const holidayInput = () =>
    editHoliday?.edit ? (
      <div className="my-1">
        {/* </div>
<div className="col-4" key="legs-gridItem-holidayName" align="center"> */}
        <input
          className="form-control form-control-sm"
          label="holiday"
          key={"holidayTitle"}
          id={"holidayTitle"}
          name="name"
          onChange={(e) => onHolidayChange(e)}
          value={holiday.name}
        />
      </div>
    ) : (
      ""
    );

  const holidayDataFields = () =>
    editHoliday?.edit ? (
      <div className="container">{showDataFields()}</div>
    ) : (
      ""
    );

  let holCols = [8, 3];
  return (
    <div className="container mt-2 p-1" key="legs-listItem-0">
      <div className="row g-1" key="legs-contain-heading">
        <div className="col-8" key="legs-gridItem-holidayDD">
          <div className="row" key="legs-holiday-edit-row-0">
            <div
              className={`col-${holCols[0]}`}
              key="legs-holiday-edit-row-1-col-1"
            >
              <HolidayDropDown visible={true} />
            </div>
            <div
              className={`col-${holCols[1]}`}
              key="legs-holiday-edit-row-1-col-2"
            >
              {currentHoliday && !editHoliday?.edit
                ? showButton(btns.edit, { edit: true })
                : ""}
            </div>
          </div>
          <div className="row" key="legs-holiday-edit-row-2">
            <div
              className={`col-${holCols[0]}`}
              key="legs-holiday-edit-row-2-col-1"
            >
              {holidayInput()}
            </div>
            <div
              className={`col-${holCols[1]} m-0 p-0`}
              key="legs-holiday-edit-row-2-col-2"
            >
              {editHoliday?.edit ? showSaveEditButtons() : ""}
              {/* {editHoliday?.edit ? stopEditHolidayButton() : ""} */}
            </div>
          </div>
        </div>
        <div className="col-4" key="legs-gridItem-holiday-btns">
          <div className="float-end">
            {showButton(btns.back)}
            {showButton(btns.pdf, { legs: legs })}
            {showButton(btns.save, {})}&nbsp;
            {shareIt("00")}
          </div>
        </div>
        <div className="row" key="legs-holiday-edit-row-details">
          <div className={`col-12`} key="legs-holiday-edit-col-details">
            {holidayDataFields()}
          </div>
        </div>
      </div>

      <LegsList
        holiday={currentHoliday ? currentHoliday : holiday}
        defaultLeg={defaultLeg}
        legsNew={legsNew}
        setLegsNew={setLegsNew}
      />
    </div>
  );
};

export default LegsMain;
