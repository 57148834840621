import React, { useContext, useEffect } from "react";
// import { Link } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import LegsMain from "../legs/Legs";

const Home = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    console.log("at the home.js page");
    authContext.loadUser();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>
        <LegsMain />
      </div>
    </div>
  );
};

export default Home;
