import React, { useEffect } from "react";
import flatpickr from "flatpickr";

const Flatpckr = ({ opts, options, value, onchange, oncleardate }) => {
  let classname = opts.key.replace(".", "-");
  let classnameDot = "." + classname;

  useEffect(() => {
    flatpickr(classnameDot, {
      ...options,
      onChange: onchange,
      altFormat: "YYYY-MM-DD",
    });

    // eslint-disable-next-line
  }, [value]);

  const onClearDate = (e, id) => {
    const fp = document.getElementById(id)._flatpickr;
    fp.clear();
    oncleardate(e, id);
  };

  const dummyFunction = (e) => {
    e.preventDefault();
  };

  // delete opts.value;
  delete opts.onChange;
  delete opts.type;
  return (
    <div className="input-group mb-1 form-floating">
      <input
        {...opts}
        className={`form-control ${classname}`}
        onChange={(e) => dummyFunction(e)}
      />
      <label htmlFor={opts.key}>{opts.placeholder}</label>
      <button
        key={`clearButton-${opts.key}`}
        id={`${opts.id}`}
        type="button"
        onClick={(e) => onClearDate(e, `${opts.id}`)}
        className="btn btn-secondary btn-sm"
      >
        X
      </button>
    </div>
  );
};

export default Flatpckr;
