import React, { Fragment, useEffect, useState } from "react";
import { returnIsNewVsOldDate, sumValues, textField } from "../../utils/common";
import { defaultTotals, totalsDef } from "./legDefinition";

const ShowTotalsRow = ({ data }) => {
  useEffect(() => {
    updateTotalsFromLegs(data);
    //eslint-disable-next-line
  }, [data]);

  const [totals, setTotals] = useState(defaultTotals);
  const onTotalsChange = (e) => {};

  const showTotalsRow = () => {
    let k = ["legL", "totals"].join("-");
    return (
      <div className="row g-1" key={k + "-container"}>
        {totalsDef(onTotalsChange).map((total, idx) => {
          return (
            <div className={`col-${total.cols}`} key={k + "-item-" + idx}>
              {textField(0, k, totals, total, {})}
            </div>
          );
        })}
      </div>
    );
  };

  const updateTotalsFromLegs = (data) => {
    const newTotals = {
      from: { dateTime: "" },
      to: { dateTime: "" },
      noOfDays: "",
      cost: "",
      ccy: "",
    };

    let d = [...data];

    // sum totals, noOfDays, earliest & latest dates
    for (let i = 0; i < d.length; i++) {
      if (d[i]?.cost?.total) {
        newTotals.cost = sumValues(newTotals.cost, d[i].cost.total);
      }
      if (d[i]?.noOfDays)
        newTotals.noOfDays = sumValues(newTotals.noOfDays, d[i].noOfDays);
      if (d[i]?.from?.dateTime)
        newTotals.from.dateTime = returnIsNewVsOldDate(
          d[i].from.dateTime,
          "<=",
          newTotals.from.dateTime
        );
      if (d[i]?.to?.dateTime)
        newTotals.to.dateTime = returnIsNewVsOldDate(
          d[i].to.dateTime,
          ">=",
          newTotals.to.dateTime
        );
    }
    setTotals(newTotals);
  };

  return <Fragment>{showTotalsRow()}</Fragment>;
};

export default ShowTotalsRow;
