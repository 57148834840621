import React, { useContext, useEffect, useState } from "react";

// import * as Mui from "@mui/material";
// import { Grid, List, ListItem, ListItemText, Button } from "@mui/material";
// import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import HolidayContext from "../../context/holiday/holidayContext";
import MyHolidayList from "./MyHolidayList";
// import LegList from "../legs/LegList";
import LegsMain from "../legs/Legs";

const HolidayTag = () => {
  const holidayContext = useContext(HolidayContext);
  const {
    getHolidays,
    currentHoliday,
    // currentDay,
    // setCurrentDay,
  } = holidayContext;

  useEffect(() => {
    getHolidays();

    // eslint-disable-next-line
  }, []);

  // const setTheDay = (next) => {
  //   setCurrentDay(
  //     !next ? currentDay.minus({ days: 1 }) : currentDay.plus({ days: 1 })
  //   );
  // };

  const [showLegs, setShowLegs] = useState(0);
  const [showResearch, setShowResearch] = useState(0);

  const showHolidayContent = () => {
    let code = "";
    if (showResearch > 0) {
      code = <LegsMain />;
    }
    if (showLegs > 0) {
      code = <LegsMain />;
    }
    return code;
  };

  return (
    <div className="row" key="legs-row-leglist-1">
      <div className="col" key="legs-col-leglist-1">
        {!currentHoliday ? <MyHolidayList /> : ""}
      </div>
      <div className="col" key="legs-col-leglist-2">
        {showHolidayContent()}
      </div>
    </div>
  );
  // return (
  //   <List key="list-0">
  //     <ListItem key="listItem-prev-0">
  //       <Grid container key="container-prev-1">
  //         <Grid item xs={2} key="gridItem-prev-0">
  //           <Button onClick={() => setTheDay(false)}>Holidays</Button>
  //         </Grid>
  //         <Grid item xs={8} key="gridItem-prev-1" align="center">
  //           <ListItemText primary={"Holiday 1"} key="listItemText-prev-1" />
  //         </Grid>
  //         <Grid item xs={2} key="gridItem-prev-2">
  //           <ListItemText primary={""} key={"listItemText-prev-2"} />
  //         </Grid>
  //       </Grid>
  //     </ListItem>
  //     <Mui.Divider key="divider-prev-05" />
  //     <ListItem sx={{ bgcolor: "blueGrey" }} key={"listItem-next-0"}>
  //       <Grid container width="100%" key={"container-next-0"}>
  //         <Grid item xs={2} key="gridItem-next-0">
  //           <ListItemText primary={"Here"} key={"listItemText-next-0"} />
  //         </Grid>
  //         <Grid item xs={8} key="gridItem-next-1" align="center">
  //           <ListItemText key="listItemText-next-1" primary={"Here2"} />
  //         </Grid>
  //         <Grid item xs={2} key="gridItem-next-2">
  //           <Button
  //             onClick={() => setTheDay(currentDay.plus({ days: 1 }).toISO())}
  //           >
  //             <ArrowRightIcon key="nextIcon-next-1" />
  //           </Button>
  //         </Grid>
  //       </Grid>
  //     </ListItem>
  //   </List>
  // );
};

export default HolidayTag;
