import React, { Fragment, useContext, useEffect, useState } from "react";
import ReactSelect from "react-select/creatable";
import HolidayContext from "../../context/holiday/holidayContext";

import { icon } from "../../utils/common";
import UserFilter from "./UserFilter";

const ShareIt = ({
  IS_MOBILE,
  idx,
  currentRecord,
  toolTip,
  // editList,
  userList,
  user,
  onShareItClick,
}) => {
  const holidayContext = useContext(HolidayContext);
  const {
    editList,
    // getEditList,
    // clearEditList
  } = holidayContext;

  let showWhat = "modal"; // "modal or dropdown"
  // let showWhat = "dropdown"; // "modal or dropdown"
  let IS_MODAL = showWhat === "modal";
  let classStart = IS_MODAL ? "container" : "dropdown-item";
  let showOpts = {
    dropdown: {
      "data-bs-toggle": "dropdown",
      "data-bs-autoclose": "false",
      "aria-expanded": "false",
    },
    modal: {
      "data-bs-toggle": "modal",
      "data-bs-target": "#shareItModal-" + idx,
    },
    modal2: {
      "data-bs-toggle": "modal",
      "data-bs-target": "#exampleModal",
    },
  };
  let opts = {
    ...showOpts[showWhat],
    className: "btn btn-sm " + (!IS_MOBILE ? "btn-primary m-0" : "m-0 p-0"),
  };
  let k = "legs-btn-share";

  // const inUserList = (list, userId) => list.some((el) => el._id === userId);
  useEffect(() => {
    console.log("shareIt.useEffect");
    // console.log(currentRecord);
    // if (currentRecord?._id) getEditList(currentRecord?._id);
    // if (currentRecord?._id) getEditList(currentRecord._id);
    // eslint-disable-next-line
  }, [editList]);

  const ddListLink = (currentHoliday, u, kLast) => {
    let k = ["legs-share-list", kLast].join("-");
    let params = {
      holidayId: currentHoliday._id,
      userId: u._id,
      newContact: {},
    };

    return (
      <div className={classStart + " m-0 p-0"} key={k + "-userlist-col-1"}>
        <div className="row">
          <div className="col-8">{u.name}</div>
          <div className="col-4">
            {["created", "pending"].includes(u.status) ? (
              <button
                className="btn btn-link m-0 p-0"
                key={k + "-mail"}
                onClick={(e) => onShareItClick(e, "mail", params)}
              >
                {icon("mail", "14pt", k + "-mail-" + idx)}
              </button>
            ) : (
              ""
            )}

            {/* {u.status === "created" ? ( */}
            <button
              className="btn btn-link m-0 p-0"
              key={k + "-remove"}
              onClick={(e) => onShareItClick(e, "remove", params)}
            >
              {icon("cancel", "14pt", k + "-remove-" + idx)}
            </button>
            {/* ) : (
              ""
            )} */}
          </div>
        </div>
      </div>
    );
  };

  const showExistingMembers = (includeStatus, excludeUser) =>
    currentRecord && editList?.length > 0
      ? editList.map((u, i) => {
          let correctStatus = includeStatus.includes(u.status);
          let userCheck = excludeUser ? u._id !== user._id : true;

          return correctStatus && userCheck
            ? ddListLink(currentRecord, u, [k, idx, i].join("-"))
            : "";
        })
      : "";

  const showContent = () => {
    let id = "shareItModal-" + idx;
    k = k + "-share-modal";
    return (
      <Fragment>
        <button
          {...opts}
          key={k + "-dd-tooltip-btn"}
          disabled={!currentRecord}
          style={{ borderWidth: "0px" }}
        >
          {!IS_MOBILE ? "SHARE" : icon("share", "1.5em", k)}
        </button>
        {IS_MODAL ? (
          <div
            className="modal fade"
            id={id}
            // id={"shareItModal-" + idx}
            tabIndex="-1"
            aria-labelledby={"exampleModalLabel"}
            aria-hidden="true"
            key={k + "-main"}
          >
            <div className="modal-dialog" key={k + "-dialog"}>
              <div className="modal-content" key={k + "-content"}>
                <div className="modal-header" key={k + "-header"}>
                  <h5
                    className="modal-title"
                    id={id + "Label"}
                    key={k + "-head"}
                  >
                    Share the trip
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    key={k + "-btn-close"}
                  ></button>
                </div>
                <div className="modal-body" key={k + "-body"}>
                  <div
                    className={(IS_MODAL ? "" : "dropdown-menu") + " px-4 py-3"}
                  >
                    {showShareComponents()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={(IS_MODAL ? "" : "dropdown-menu") + " px-4 py-3"}>
            <div>{showShareComponents()}</div>
            <select>
              <option>Test</option>
              <option>Test2</option>
            </select>
            <ReactSelect
              options={[
                { label: "Test", value: "1" },
                { label: "Test2", value: "2" },
              ]}
            />
          </div>
        )}
      </Fragment>
    );
  };

  const showShareComponents = () => (
    <div>
      {/* users with edit access already */}
      {showExistingMembers(["created"], false)}
      {/* users without edit access already */}
      {showExistingMembers(["pending"], false)}
      {/* section to add users */}
      {editList && userList?.length > 0 ? (
        <div className="dropdown-divider"></div>
      ) : (
        ""
      )}
      <div className="dropdown-divider"></div>
      {showAddUser()}
      <div className="dropdown-divider"></div>
      {searchExistingUsers()}
    </div>
  );

  const searchExistingUsers = () => {
    let exclude = [];
    if (user?._id && editList?.length)
      exclude = [...editList.map((e) => e._id), user._id];
    if (user?._id) exclude = [...exclude, user._id];
    let onchange = (e) =>
      onShareItClick(e, "add-existing", {
        holidayId: currentRecord._id,
        userId: e.value,
        newContact: {},
      });

    return (
      // <div className="g-1 p-0 mx-0">
      <div className={classStart + " g-1 m-0 p-1"}>
        <span>
          <span className="h6">Search</span>{" "}
        </span>
        <UserFilter
          opts={{ onChange: onchange }}
          excludeValues={exclude}
          scope={""}
          value={""}
        />
      </div>
    );
  };

  const addNewUser = (e, action, params) => {
    onShareItClick(e, action, params);
    setNewUser({ _id: "new", name: "", email: "" });
  };

  const [newUser, setNewUser] = useState({ _id: "new", name: "", email: "" });
  const showAddUser = () => {
    let onchange = (e) =>
      addNewUser(e, "add-new", {
        holidayId: currentRecord._id,
        userId: "",
        newContact: newUser,
      });

    return (
      // <div className="g-1 p-0 mx-0">
      <div className={classStart + " g-1 m-0 p-1"}>
        <span>
          <span className="h6">Share to Others</span>{" "}
          <button
            type="button"
            className="btn btn-sm btn-primary"
            key={k + "-newMemBtn-" + idx}
            onClick={onchange}
          >
            ADD
          </button>
        </span>
        <input
          id="newName"
          className="form-control form-control-sm"
          type="text"
          key={k + "-newName-" + idx}
          placeholder="name"
          onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
        />
        <input
          id="newEmail"
          className="form-control form-control-sm"
          type="text"
          key={k + "-newEmail-" + idx}
          placeholder="email"
          onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
        />
      </div>
    );
  };

  return (
    <Fragment>
      {toolTip ? (
        <span
          key={k + "-dd-tooltip"}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={toolTip}
        >
          {showContent()}
        </span>
      ) : (
        showContent()
      )}
    </Fragment>
  );
};

export default ShareIt;
